import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="stage" className="maedels animated-stage">
          <div className="container">
            <div className="row text-center">
              <div className="stage-text col-md-10 col-md-offset-1">
                <h1>Unsere Mädels</h1>
                <p>
                  Wir sind die <strong>bebe Zartpflege® Freundinnen</strong> und
                  spielen gerne alle gemeinsam. Unser Lieblingsprodukt ist die{" "}
                  <strong>bebe Zartpflege® Zartcreme</strong>. Die besonders
                  milde Hautpflege schützt die Haut vor dem Austrocknen und
                  zieht schnell ein. Und Vitamin E ist auch mit drin.{" "}
                </p>
              </div>
              <div className="stage-image maedels col-xs-12">
                {" "}
                <img
                  className="stage-bg"
                  alt="bebe Zartpflege®"
                  src="/fileadmin/images/produkte/produkte.stage.img.png"
                  width="360"
                  height="310"
                />
                <div className="animated-items">
                  {" "}
                  <a
                    href="/unsere-maedels/#bibiblubberblase"
                    target="_self"
                    className="textlink-scroll animated-item item-1 animate"
                    style={{
                      opacity: "1"
                    }}
                  >
                    <img
                      src="/fileadmin/images/maedels/stage/bibi-bubberblase.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/unsere-maedels/#miamorgentau"
                    target="_self"
                    className="textlink-scroll animated-item item-2 animate"
                    style={{
                      opacity: "1"
                    }}
                  >
                    <img
                      src="/fileadmin/images/maedels/stage/mia-morgentau.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/unsere-maedels/#paulaperlensanft"
                    target="_self"
                    className="textlink-scroll animated-item item-3 animate"
                    style={{
                      opacity: "1"
                    }}
                  >
                    <img
                      src="/fileadmin/images/maedels/stage/paula-perlensanft.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/unsere-maedels/#viviwallemaehne"
                    target="_self"
                    className="textlink-scroll animated-item item-4 animate"
                    style={{
                      opacity: "1"
                    }}
                  >
                    <img
                      src="/fileadmin/images/maedels/stage/vivi-wallemaehne.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/unsere-maedels/#sophiesonnenschein"
                    target="_self"
                    className="textlink-scroll animated-item item-5 animate"
                    style={{
                      opacity: "1"
                    }}
                  >
                    <img
                      src="/fileadmin/images/maedels/stage/sophie-sonnenschein.png"
                      alt
                    />
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="bubbles-container">
              <div className="bubble1" />
              <div className="bubble2" />
              <div className="bubble3" />
              <div className="bubble4" />
            </div>
          </div>
        </div>
        <div className="scroll-bottom-arrow">
          <div className="arrow-animated" />
          <div className="arrow-hint" />
        </div>
        <div id="content" className="limited_editions">
          <div className="container">
            {" "}
            <a name="BibiBlubberblase" />
            <div id="bibiblubberblase" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Bibi Blubberblase</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/maedels/bebe-zartpflege-relaunch2019_maedels_bibi.png"
                  width="422"
                  height="348"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                  Mein Name ist Bibi Blubberblase und ich liebe es zu baden.
                  Dabei plansche ich immer so wild, dass ich das ganze
                  Badezimmer in ein großes Blubberbad verwandle. <br />
                  Das <strong>bebe Zartpflege® Schaumbad und Dusche</strong> ist
                  toll, weil es meine Haut sanft reinigt.
                </p>
              </div>
            </div>
            <a name="MiaMorgentau" />
            <div id="miamorgentau" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Mia Morgentau</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/maedels/bebe-zartpflege-relaunch2019_maedels_mia.png"
                  width="422"
                  height="348"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Mein Name ist Mia Morgentau und ich mag es, frisch und
                  wohlriechend in den Tag zu starten. Deshalb freue ich mich
                  immer auf das morgendliche Händewaschen nach dem Aufstehen.
                  <br />
                  Die <strong>bebe Zartpflege® Milde Handseife</strong> mag ich
                  besonders gerne, weil sie Schmutz und Bakterien effektiv
                  entfernt.
                </p>
              </div>
            </div>
            <a name="PaulaPerlensanft" />
            <div id="paulaperlensanft" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Paula Perlensanft</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/maedels/bebe-zartpflege-relaunch2019_maedels_paula.png"
                  width="422"
                  height="348"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                  Ich bin Paula Perlensanft und Teddy ist mein ständiger
                  Begleiter. Am liebsten spiele ich mit meinen Kuscheltieren
                  oder lese Bücher.
                  <br />
                  Mein liebstes Produkt ist die{" "}
                  <strong>bebe Zartpflege® Pflegelotion</strong>, weil sie
                  schnell einzieht.{" "}
                </p>
              </div>
            </div>
            <a name="ViviWallemaehne" />
            <div id="viviwallemaehne" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Vivi Wallemähne</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/maedels/bebe-zartpflege-relaunch2019_maedels_vivi.png"
                  width="422"
                  height="348"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Ich bin Vivi Wallemähne und ich spiele am liebsten mit meinen
                  Puppen. Dabei wasche ich ihnen die Haare, flechte ihnen Zöpfe
                  und fahre sie im Kinderwagen auf der Wiese herum.
                  <br />
                  Das <strong>bebe Zartpflege® Glanz Shampoo</strong> mag ich
                  gerne, weil meine Haare dadurch so schön glänzen –&nbsp; und
                  das ganz ohne Tränen.
                </p>
              </div>
            </div>
            <a name="SophieSonnenschein" />
            <div id="sophiesonnenschein" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Sophie Sonnenschein</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/maedels/bebe-zartpflege-relaunch2019_maedels_sofia.png"
                  width="422"
                  height="348"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                  Ich bin Sophie Sonnenschein und ich liebe die Natur – summende
                  Bienen, blühende Blumen und leckeres Eis.
                  <br />
                  Wenn das Eis schmilzt und meine Hände kleben, benutze ich
                  gerne die <strong>
                    bebe Zartpflege® Reinigungstücher
                  </strong>{" "}
                  mit milder Reinigungslotion.
                </p>
              </div>
            </div>
            <a name="Malbuch" />
            <div id="malbuch" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Mal- &amp; Rätselbuch</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/maedels/bebe-zartpflege-relaunch2019_maedels_malbuch.png"
                  width="422"
                  height="348"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Du kannst gar nicht genug von unseren Mädels bekommen? Dann
                  löse doch zusammen mit ihnen spannende Rätsel und lasse ihre
                  Welt mit ein paar Buntstiften im farbigen Glanz erscheinen!{" "}
                </p>
                <p>
                  <a
                    href="/fileadmin/bebe_Zartpflege_Malbuch105x148.pdf"
                    target="_blank"
                    className="download"
                  >
                    Malbuch herunterladen &gt;
                  </a>
                </p>
              </div>
            </div>
            <a name="HannaHeldenhaft" />
            <div id="sophiesonnenschein" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Hanna Heldenhaft</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/maedels/StaerkendesShampoo_HannaHeldenhaft.png"
                  width="422"
                  height="348"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                Wenn Hanna und Pia sich sehen, dann hat der Tag 
                zu wenige Stunden. Hanna liebt es, Pia von ihren 
                Abenteuern zu erzählen! Pia hört ihr ganz gespannt
                zu und hat immer erfrischende Ideen, welcher Herausforderung 
                sich Hanna als nächstes stellen kann. Hanna‘s 
                Lieblingsprodukt ist das <strong>bebe Zartpflege ® stärkende
                Shampoo und Spülung</strong>.
                  <br />
                  Wenn das Eis schmilzt und meine Hände kleben, benutze ich
                  gerne die <strong>
                    bebe Zartpflege® Reinigungstücher
                  </strong>{" "}
                  mit milder Reinigungslotion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
